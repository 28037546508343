import React from "react";
import { Button } from "../templates/buttons";
import PropTypes from "prop-types";
import { Modal } from "../templates/modal";

function FormSubmittedModal({ type, title, action }) {
  return (
    <Modal id="form-submitted" title={title}>
      <p>
        Your {type} was {action} successfully!
      </p>
      <Button link="/" type="home" text="Go Home" />
    </Modal>
  );
}

FormSubmittedModal.propTypes = {
  type: PropTypes.string,
  title: PropTypes.string,
  action: PropTypes.string,
};

export default FormSubmittedModal;
