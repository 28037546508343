import React from "react";
import { useStaticQuery, graphql, Link } from "gatsby";
import Breadcrumbs from "../breadcrumbs";

const FeedbackForm = () => {
  const data = useStaticQuery(
    graphql`
      query FeedbackPackTopics {
        allMarkdownRemark(sort: { fields: [frontmatter___title], order: ASC }, filter: { frontmatter: { categoryid: { eq: "packs" } } }) {
          edges {
            node {
              frontmatter {
                title
                type
              }
            }
          }
        }
      }
    `
  );

  const { allMarkdownRemark } = data;
  return (
    <section>
      <Breadcrumbs>
        <span>Feedback Submission</span>
      </Breadcrumbs>
      <form name="Feedback Submission" accept-charset="utf-8" method="POST" action="/feedback?submitted=true" data-netlify="true">
        <fieldset>
          <label for="title">Title</label>
          <input type="text" name="Title" id="title" placeholder="Enter a title for your suggestion" required></input>
          <label for="topic">Topic</label>
          <select name="Topic" id="topic" required>
            <option value="Select" selected disabled>
              Select a topic
            </option>
            <option value="Website">Website</option>
            <option value="New Pack">New Pack Idea</option>
            {allMarkdownRemark.edges.map(({ node }) => (
              <option value={node.frontmatter.title}>{node.frontmatter.title}</option>
            ))}
          </select>
          <label for="details">Details</label>
          <textarea rows="5" name="Details" id="details" placeholder="Enter a detailed description of your suggestion" required></textarea>
          <input type="hidden" name="form-name" value="Feedback Submission" />
        </fieldset>
        <input className="button button--submit-form button--feedback" type="submit" value="Submit Feedback"></input>
      </form>
      <h5>Form submissions are only viewed by QuazChick team members.</h5>
    </section>
  );
};

export default FeedbackForm;
