import React, { useEffect } from "react";
import Seo from "../components/seo";
import Default from "../components/default";
import FeedbackForm from "../components/forms/feedbackForm";
import FormSubmittedModal from "../components/modals/formSubmittedModal";

const FeedbackPage = () => {
  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    if (queryParams.get("submitted") === "true") {
      document.getElementById(`form-submitted-modal`).setAttribute("status", "open");
      document.getElementById(`form-submitted-modal-close`).focus();
    }
  });

  return (
    <>
      <Seo title="Feedback Submission" />
      <Default>
        <FeedbackForm />
        <FormSubmittedModal title="Feedback" type="feedback" action="submitted" />
      </Default>
    </>
  );
};

export default FeedbackPage;
